export default {
  label: {
    fontSize: "sm",
    mb: 1,
  },
  input: {
    borderColor: "text",
    "&:focus": {
      borderColor: "primary",
      boxShadow: theme => `0 0 0 2px ${theme.colors.primary}`,
      outline: "none",
    },
  },
  textarea: {
    variant: "forms.input",
  },
};
