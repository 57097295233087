export default {
  breakpoints: [
    // 0
    "768px",
    "1024px"
  ],
  space: [
    0, // 0
    4,
    8,
    16,
    32,
    64,
    128,
    256,
    512
  ],
  sizes: {
    container: "1280px",
  },
  zIndices: {
    top: 9999,
    header: 100,
  },
};
