export default {
  background:        "#f1f1f1", // body background color
  text:              "#2b2e32", // body color
  primary:           "#E71D36", // primary button and link color
  secondary:         "#ED5A6C", // secondary color - can be used for hover states
  accent:            "#011627", // a contrast color for emphasizing UI
  highlight:         "", // a background color for highlighting text
  muted:             "", // a gray or subdued color for decorative purposes
  "red-rose":        "#E71D36",
  "white-powder":    "#FDFFFC",
  "white-clean":     "#ffffff",
  "yellow-crayola":  "#FF9F1C",
  "blue-maximum":    "#3dbbaf",
  "blue-maastricht": "#011627",
  modes: {
    dark: {
      // styles specific for dark mode
      text:          "#FDFFFC",
      background:    "#011627"
    },
  },
};
