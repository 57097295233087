export default {
  buttons: {
    primary: {
      transition: "background-color 0.25s",
      fontWeight: "body",
      fontFamily: "body",
      px: 3,
      py: 2,
      ":hover": {
        bg: "secondary",
        cursor: "pointer",
      }
    },
    large: {
      variant: "buttons.primary",
      px: 4,
      py: 3,
      fontSize: "md",
    },
    secondary: {
      variant: "buttons.primary",
      bg: "transparent",
      border: (theme) => `2px solid ${theme.colors.primary}`,
      color: "primary",
      ":hover": {
        bg: "transparent",
        cursor: "pointer",
      }
    },
    transparent: {
      bg: "transparent",
      p: "0px",
      ":hover": {
        cursor: "pointer",
      },
    }
  },
  links: {
    nav: {
      fontWeight: "body",
      fontSize: "md",
      color: "modes.dark.text",
      mx: 3,
    },
  },
};
