import colors from "./colors";
import dimensions from "./dimensions";
import typography from "./typography";

// Components
import links from "./links";
import forms from "./forms";

export default {
  colors,
  ...typography,
  ...dimensions,
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
    },
    a: {
      color: "primary",
    },
  },
  // Components
  ...links,
  forms,
  // Decoration
  // TODO: Could these be in an object and better named? (e.g. none, small, large)
  radii: [
    0, // 0
    5,
    20
  ],
}
