const FONT_FAMILY = 'Lato, "Lucida Grande", Tahoma, Sans-Serif';

export default {
  // useColorSchemeMediaQuery: true, TODO: Uncomment this when we have fully completed the dark mode colours
  fonts: {
    body: FONT_FAMILY,
    heading: FONT_FAMILY,
  },
  // TODO: Adjust font sizes to match design better, we currently don't use lg and xl so normalize
  fontSizes: {
    xs: 12, // 0
    sm: 16,
    md: 19,
    lg: 28,
    xl: 48,
  },
  // TODO: Could these be better named? (e.g. normal, medium, bold)
  fontWeights: {
    bold: 700,
    heading: 400,
    body: 300,
  },
  // TODO: Could these be better named? (e.g. normal, medium)
  lineHeights: {
    heading: 1.3,
    body: 1.5,
  },
  letterSpacing: { },
  // Variants for use with components e.g. Heading, Text or on standard divs
  text: {
    lite: {
      fontWeight: "body",
      mt: 2
    },
    visible: {
      lineHeight: 1.7,
      fontSize: "lg",
    }
  },
};
